<template>
  <v-container>
    <v-card>
      <component v-bind:is="'Cookies' + activeLocale.toUpperCase()" />
    </v-card>
  </v-container>
</template>

<script>
import CookiesCS from '../components/Cookies/Cookies-cs';
import CookiesEN from '../components/Cookies/Cookies-en';
export default {
  name: 'CookiePolicy',
  components: {
    CookiesCS,
    CookiesEN
  },
  data() {
    return {
      activeLocale: 'cs'
    }
  },
  mounted() {
    this.activeLocale = this.$i18n.locale;
  },
  watch: {
    "$i18n.locale": function (val) {
      this.activeLocale = val;
    },
  }
}
</script>
