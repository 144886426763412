<template>
  <div>
    <v-overlay :value="checkingCustomer">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CheckCustomer",
  mounted() {
    let customerId = this.$router.currentRoute.query.readerNumber;
    if (typeof customerId === "undefined" || !customerId.length) {
      this.$router.push("/");
      return;
    }

    const customer = {
      couponOrCardNumber: customerId,
      email: this.$router.currentRoute.query.email,
      firstName: this.$router.currentRoute.query.firstName,
      lastName: this.$router.currentRoute.query.lastName,
    };

    this.checkCustomer({
      customer: customer,
      lang: this.$router.currentRoute.query.lang,
    });
  },
  computed: {
    ...mapGetters(["checkingCustomer"]),
  },
  methods: {
    ...mapActions(["checkCustomer"]),
  },
};
</script>
