import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

let navigatorLang;

if (navigator.language.includes("en")) {
  navigatorLang = "en";
}

if (navigator.language.includes("cs")) {
  navigatorLang = "cs";
}

const storeVuex = JSON.parse(localStorage.getItem("vuex"));
let storeLang;
if (!storeVuex) {
  storeLang = navigatorLang;
} else {
  storeLang = storeVuex.lang == "cz" ? "cs" : storeVuex.lang;
}

export default new VueI18n({
  locale:
    params.lang ||
    storeLang ||
    navigatorLang ||
    process.env.VUE_APP_I18N_LOCALE ||
    "en",
  fallbackLocale:
    params.lang ||
    storeLang ||
    navigatorLang ||
    process.env.VUE_APP_I18N_FALLBACK_LOCALE ||
    "en",
  messages: loadLocaleMessages(),
});

export const LOCALES = ["cs", "en"];
