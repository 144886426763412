<template>
    <v-snackbar
            v-model="snackbar.active"
            :color="snackbar.type"
            :timeout="-1"
    >
        {{ snackbar.message }}
        <v-btn
                text
                @click="hideSnackbar"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'Snackbar',
        computed: {
            ...mapGetters([
                'snackbar'
            ])
        },
        methods: {
            ...mapActions([
                'hideSnackbar'
            ])
        }
    }
</script>
