<template>
    <v-container fluid class="grey lighten-3">
        <copyright />
    </v-container>
</template>

<script>
    import Copyright from "./Copyright";

    export default {
        name: 'Footer',
        props: ['isCheckout'],
        components: {
            Copyright
        }
    }
</script>
