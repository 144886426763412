import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#227783",
        secondary: "#f78849",
        info: "#4583f4",
        success: "#8BC34A",
        error: "#e80028"
      },
      dark: {
        primary: "#227783",
        secondary: "#f78849",
        info: "#4583f4",
        success: "#8BC34A",
        error: "#e80028"
      }
    }
  }
})
