<template>
  <div>
    <v-btn block :loading="loading" :disabled="loading" color="primary" depressed tile large @click="submit($event)">{{$t('checkout.placeOrder')}}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'PlaceOrder',
  props: ['form', 'loading'],
  data () {
    return {
      rules: window.validationRules.getRules()
    }
  },
  methods: {
    submit(event) {
      this.$emit('submit', event);
    }
  }
}
</script>
