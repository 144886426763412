<template>
  <v-container fluid grid-list-lg class="cart pa-0">
    <v-container fluid class="grey lighten-3 white--text">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2
            class="font-weight-light text-uppercase py-2 mb-0 black--text"
            :class="{ 'text-h5': $vuetify.breakpoint.smAndUp }"
          >
            {{ $t("category.subscriptions") }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage, formatThousands } from "../Helper";

export default {
  name: "Cart",
  mixins: [getProductImage, formatThousands],
  computed: {
    ...mapGetters(["allProducts", "cartProducts", "user"]),

    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
    total() {
      if (this.cartProducts[0] === null) return null;
      return this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);
    },
    totalDiscount() {
      if (this.cartProducts[0] === null) return null;
      let total = this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);

      if (this.discount) {
        return parseInt(total) - this.discount;
      }

      return total;
    },
    discount() {
      if (this.user && this.user.discount) {
        return Math.round(this.total * this.user.discount);
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["changeQuantity", "removeFromCart"]),
    getPrice(price, quantity) {
      return quantity * price;
    },
    changeValue(product) {
      this.changeQuantity(product);
    },
  },
};
</script>

<style lang="scss">
.cart {
  .mobile-table {
    font-size: 14px;
  }
  .item-price {
    font-size: 16px;
  }
  .order-total {
    font-size: 18px;
    .amount {
      font-size: 22px;
    }
  }
  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }
    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
      &:hover {
        background: #fa3600;
      }
    }
  }
  .empty-cart {
    font-size: 86px !important;
    color: rgba(135, 135, 135, 0.15) !important;
  }
}
</style>

<style lang="scss">
.v-list-item__title {
  white-space: unset;
}
</style>
