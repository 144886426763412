<template>
  <cookie-law theme="dark-lime">
    <v-layout slot-scope="props">
      <v-flex xs12 sm8>
        {{ $t('cookieBarPolicy.text') }}<router-link to="/privacy" class="success-text">{{ $t('cookieBarPolicy.link') }}</router-link>.
      </v-flex>
      <v-flex xs12 sm4 text-right>
        <v-btn color="primary" @click="props.accept">{{ $t('cookieBarPolicy.button') }}</v-btn>
      </v-flex>
    </v-layout>
  </cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: { CookieLaw }
}
</script>

<style>
a.success-text {
  color: #8BC34A !important;
}
</style>