<template>
  <v-container class="main-header white">
    <v-row class="align-center">
      <v-col class="primary--text main-title align-center">
        <router-link class="text-decoration-none" to="/">
          <img :src="require(`@/assets/${$t('homepage.logo')}`)" />
        </router-link>
      </v-col>
      <v-spacer></v-spacer>
      <v-menu offset-y tile>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :small="$vuetify.breakpoint.xsOnly"
            color="primary"
            dark
            tile
            depressed
            v-bind="attrs"
            v-on="on"
            class="mr-1"
          >
            {{ activeLocale }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in locales"
            :key="index"
            @click="changeLocale(item)"
          >
            <v-list-item-title class="text-uppercase">{{
              item
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom :disabled="itemsInCart > 0">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-inline-block">
            <v-badge
              :content="itemsInCart"
              :value="itemsInCart"
              color="primary"
              overlap
            >
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                tile
                :color="!itemsInCart ? 'primary' : 'white'"
                :disabled="!itemsInCart"
                @click="navigateToCheckout"
              >
                <v-icon color="primary" :left="$vuetify.breakpoint.smAndUp"
                  >mdi-cart-check</v-icon
                >
                <span v-if="$vuetify.breakpoint.smAndUp">{{
                  $t("cart.checkout")
                }}</span>
              </v-btn>
            </v-badge>
          </div>
        </template>
        <span>{{ $t("cart.addToCartFirst") }}</span>
      </v-tooltip>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { LOCALES } from "@/i18n";

export default {
  name: "MainHeader",
  props: ["isCheckout"],
  data() {
    return {
      drawer: false,
      locales: LOCALES,
      activeLocale: null,
    };
  },
  computed: {
    ...mapGetters(["cartProducts"]),
    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
  },
  mounted() {
    this.activeLocale = this.$i18n.locale;
  },
  methods: {
    navigateToCheckout() {
      if (this.$router.currentRoute.name !== "Checkout") {
        this.$router.push("/checkout");
      }
    },
    changeLocale(item) {
      this.activeLocale = item;
      this.$i18n.locale = item;
      this.$store.dispatch("setLang", item);
    },
  },
};
</script>

<style lang="scss">
.main-header {
  .main-title {
    font-size: 22px;
    letter-spacing: 0.1em !important;
  }
  .cart-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .mobileCart {
    max-width: 80px;
  }
  &.isCheckout {
    padding-top: 0;
    padding-bottom: 0;
  }
  .menu-item {
    a {
      padding: 0 1em;
      font-size: 16px;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .custom-logo {
    margin-top: 5px;
    max-width: 180px;
    height: 115px;
    &.isCheckout {
      max-width: 150px;
    }
  }
  .menu-icon-link {
    border-radius: 7px !important;
  }
  .v-list,
  .v-menu__content {
    border-radius: 0 !important;
  }
  .v-menu__content {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
  }
  .v-chip {
    .v-chip__content {
      cursor: pointer !important;
    }
  }
  .opening-hours {
    strong {
      display: block;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
</style>
