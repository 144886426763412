<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <h3 class="checkout-section-title">
        {{ $t("checkout.paymentMethods") }}
      </h3>
    </v-card-title>
    <v-card-text>
      <v-radio-group
        v-model="form.paymentMethodId"
        v-if="paymentMethods.length"
        :rules="[rules.required]"
      >
        <v-radio
          v-for="n in paymentMethods"
          :key="n.id"
          :label="n.name"
          :value="n.id"
        >
          <template v-slot:label>
            <div class="align-center d-flex">
              <span
                class="d-inline-flex"
                style="align-items: flex-start"
                v-if="n.iconName"
                ><img class="payment-icon" :src="getPaymentIcon(n.iconName)"
              /></span>
              <span class="d-inline-flex">{{ n.name }}</span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div v-if="!paymentMethods.length">
        {{ $t("checkout.noPaymentMethods") }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PaymentMethods",
  props: ["form"],
  data() {
    return {
      loadingMethods: false,
      rules: window.validationRules.getRules(),
    };
  },
  mounted() {
    if (!this.paymentMethods.length) {
      this.retrievePaymentMethods(this.$i18n.locale);
    }
  },
  watch: {
    "$i18n.locale": function (val) {
      this.retrievePaymentMethods(val);
    },
  },
  computed: {
    ...mapGetters(["paymentMethods", "paymentMethodsLoaded"]),
  },
  methods: {
    ...mapActions(["retrievePaymentMethods"]),
    getPaymentIcon(icon) {
      return require("../../assets/payment_methods/" + icon);
    },
  },
};
</script>

<style lang="scss">
.payment-icon {
  max-width: 100px;
  margin-right: 10px;
}
</style>
