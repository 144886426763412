<template>
  <v-container class="black--text copyright pa-0 text-center text-sm-left">
    <v-row
      class="align-center"
      :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
    >
      <v-col class="text-center text-sm-right">
        <a
          target="_blank"
          class="black--text mr-2"
          href="https://www.nkp.cz/"
          >{{ $t("footer.title") }}</a
        >
        <a
          target="_blank"
          class="black--text"
          :href="$t('footer.privacyPolicyPdf')"
          >{{ $t("footer.privacyPolicy") }}</a
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style lang="scss">
.copyright {
  font-size: 14px;
}
.no-decoration {
  text-decoration: none;
}
</style>
